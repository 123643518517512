<template>
  <div class="wardening-page">
    <div class="wardening-page-search d-flex">
      <input
        v-model="search"
        placeholder="Car.num."
        data-type="search"
        class="wardening-page-search-input"
      />
      <button class="wardening-page-search-button" @click="searchClick">
        {{ $t('search') }}
      </button>
    </div>
    <ul class="wardening-page-list pa-0">
      <li
        v-for="item in filteredList"
        class="wardening-page-list-item d-flex justify-space-between align-center"
        :key="item.licence_plate"
      >
        <div class="wardening-page-list-item-data">
          <div class="wardening-page-list-item-data-licence">
            {{ item.licence_plate }}
          </div>
          <div class="wardening-page-list-item-data-time d-flex">
            <span class="d-flex align-center">
              <div class="green-circle mr-1"></div>
              {{ formatedStartTime(item.start_timestamp, item.timezone) }}
            </span>
            <span class="d-flex align-center">
              <v-icon class="ml-2 mr-1" size="14">mdi-clock-outline</v-icon>
              {{ durationTime(item.start_timestamp) }}
            </span>
          </div>
        </div>
        <div class="wardening-page-list-item-zone">
          <v-chip color="#333333" variant="flat" x-small>
            Zone {{ item.zone }}
          </v-chip>
        </div>
      </li>
      <li
        v-if="listIsEmpty"
        class="wardening-page-list-item d-flex justify-space-between align-center"
      >
        <div class="wardening-page-list-item-data">
          <div class="wardening-page-list-item-data-licence">No results</div>
          <div class="wardening-page-list-item-data-time ml-4">
            <b>{{ $t('note') }}!</b> {{ $t('warden_empty_list_note') }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import api from '@/helpers/api'
import moment from 'moment-timezone'

export default {
  name: 'WardeningPage',
  components: {},
  data() {
    return {
      fullParkingsList: [],
      search: ''
    }
  },
  mounted() {
    this.loadData()
  },
  computed: {
    filteredList() {
      return this.search.length > 1
        ? this.fullParkingsList.parkings.filter((item) => {
            return (
              item.licence_plate
                .toUpperCase()
                .includes(this.search.toUpperCase()) ||
              item.zone.toUpperCase().includes(this.search.toUpperCase())
            )
          })
        : this.fullParkingsList.parkings
    },
    listIsEmpty() {
      return !this.filteredList?.length
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch('setShowOverlay', true)
      const apiSettings = {
        method: 'get',
        service: 'merchant',
        url: `wardens/${this.$route.params.wardenName}/${this.$route.params.wardenKey}/web`
      }
      api
        .promise(apiSettings)
        .then((response) => {
          this.fullParkingsList = response.data
        })
        .finally(() => {
          this.$store.dispatch('setShowOverlay', false)
        })
    },
    formatedStartTime(seconds, tz) {
      return moment.tz(moment.unix(seconds), tz).format('HH:mm:ss')
    },
    durationTime(seconds) {
      const duration = moment().unix() - seconds
      return moment.unix(duration).format('HH:mm:ss')
    },
    searchClick() {
      if (this.listIsEmpty) {
        this.search = ''
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/style/common';
.wardening-page {
  width: 100%;
  padding: 0 15px;
  &-search {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    position: relative;
    &-input {
      width: 100%;
      padding: 6px;
      font-size: 14px;
      display: block;
      width: 100%;
      background-color: #f7f7f7;
      border-radius: 0;
      color: #000000;
      box-shadow: none;
      &:focus {
        outline: none;
      }
    }
    &-button {
      background-color: #333333;
      color: #ffffff;
      height: 100%;
      outline: 0 none;
      position: absolute;
      right: 0;
      top: 0;
      width: 112px;
      z-index: 100;
      font-weight: bold;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
  &-list {
    list-style: none;
    &-item {
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 10px;
      padding-bottom: 10px;
      &-data {
        text-align: start;
        &-licence {
          font-size: 16pt;
          font-weight: 500;
        }
        &-time {
          font-size: 10px;
          .green-circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #37bd00;
          }
        }
      }
      &-zone {
        .v-chip {
          &__content {
            color: #ffffff;
            font-size: 10px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
